import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "starting-with-create-react-app-lets-install-carbon-and-begin-using-carbon-components-by-the-end-you-will-have-a-react-app-that-uses-the-ui-shell-to-navigate-between-pages"
    }}>{`Starting with Create React App, let’s install Carbon and begin using Carbon components. By the end you will have a React app that uses the UI Shell to navigate between pages.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Build and start</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install Carbon</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install and build Sass</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add UI Shell</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create pages</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add routing</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "preview"
    }}>{`Preview`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://react-step-2--carbon-tutorial.netlify.com"
      }}>{`preview`}</a>{` of what you will build:`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe height="200" title="Carbon Tutorial Step 1" src="https://react-step-2--carbon-tutorial.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" />
  </Column>
    </Row>
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` that we’ll use as a starting point for each step.`}</p>
    <h3 {...{
      "id": "fork"
    }}>{`Fork`}</h3>
    <p>{`To begin, fork `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` using your GitHub account.`}</p>
    <h3 {...{
      "id": "clone"
    }}>{`Clone`}</h3>
    <p>{`Go to your forked repository, copy the SSH or HTTPS URL and in your terminal run the two commands to get the repository in your local file system and enter that directory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git clone [your fork SSH/HTTPS]
$ cd carbon-tutorial
`}</code></pre>
    <h3 {...{
      "id": "add-upstream-remote"
    }}>{`Add upstream remote`}</h3>
    <p>{`Add a remote called `}<inlineCode parentName="p">{`upstream`}</inlineCode>{` so we can eventually submit a pull request once you have completed this tutorial step.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git remote add upstream git@github.com:carbon-design-system/carbon-tutorial.git
`}</code></pre>
    <p>{`Or, if you prefer to use HTTPS instead of SSH with your remotes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git remote add upstream https://github.com/carbon-design-system/carbon-tutorial.git
`}</code></pre>
    <p>{`Verify that your forked repository remotes are correct:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git remote -v
`}</code></pre>
    <p>{`Your terminal should output something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`origin  [your forked repo] (fetch)
origin  [your forked repo] (push)
upstream    git@github.com:carbon-design-system/carbon-tutorial.git (fetch)
upstream    git@github.com:carbon-design-system/carbon-tutorial.git (push)
`}</code></pre>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`Now that we have our repository set up, let’s check out the branch for this tutorial step’s starting point. Run the two commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b react-step-1 upstream/react-step-1
`}</code></pre>
    <h2 {...{
      "id": "build-and-start"
    }}>{`Build and start`}</h2>
    <p>{`We have the repository forked to your GitHub account, cloned down to your machine, and the starting branch checked out. Next, install the React app’s dependencies with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`After the dependencies are installed, you can start the app with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn start
`}</code></pre>
    <p>{`Your default browser should open up with an empty page that says: `}<inlineCode parentName="p">{`Hello Carbon! Well, not quite yet. This is the starting point for the Carbon tutorial.`}</inlineCode></p>
    <h2 {...{
      "id": "install-carbon"
    }}>{`Install Carbon`}</h2>
    <p>{`Even though we installed existing dependencies, we’ve yet to install the Carbon packages.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`carbon-components`}</inlineCode>{` - component styles`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`carbon-components-react`}</inlineCode>{` - React components`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@carbon/icons-react`}</inlineCode>{` - React icons`}</li>
    </ul>
    <p>{`Stop your development server with `}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{` and install Carbon dependencies with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add carbon-components carbon-components-react @carbon/icons-react
`}</code></pre>
    <h2 {...{
      "id": "install-and-build-sass"
    }}>{`Install and build Sass`}</h2>
    <p>{`We need to run a Sass build as the Carbon styles are authored in Sass, so run the following command to install `}<inlineCode parentName="p">{`node-sass`}</inlineCode>{` as a dependency.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add node-sass
`}</code></pre>
    <p>{`To avoid having to add the `}<inlineCode parentName="p">{`~`}</inlineCode>{` prefix when importing SCSS files from `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{`, create a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file at the project root that contains:`}</p>
    <h5 {...{
      "id": "env"
    }}>{`.env`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`SASS_PATH="node_modules"
`}</code></pre>
    <p>{`For the Windows operating system, use:`}</p>
    <h5 {...{
      "id": "env-1"
    }}>{`.env`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`SASS_PATH=./node_modules
`}</code></pre>
    <p>{`Then, start the app again. If your app’s currently running, you’ll need to restart it for the new environment variable to be used.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn start
`}</code></pre>
    <p>{`The app looks as it did before. Next, let’s prepare our app for a Sass build.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory, rename `}<inlineCode parentName="p">{`index.css`}</inlineCode>{` as `}<inlineCode parentName="p">{`index.scss`}</inlineCode>{`. Then in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` update the `}<inlineCode parentName="p">{`index.css`}</inlineCode>{` import to `}<inlineCode parentName="p">{`index.scss`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "import-carbon-component-styles"
    }}>{`Import carbon-component styles`}</h3>
    <p>{`In `}<inlineCode parentName="p">{`index.scss`}</inlineCode>{`, import the Carbon styles by adding the following at the top of the file:`}</p>
    <h5 {...{
      "id": "srcindexscss"
    }}>{`src/index.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import 'carbon-components/scss/globals/scss/styles.scss';
`}</code></pre>
    <p>{`This will take a moment for the Sass to compile. Once compiled, you’ll notice that the Carbon base styling is applied (IBM Plex Sans font family, font size, weight, colors, etc.)`}</p>
    <p>{`Because any change to `}<inlineCode parentName="p">{`index.scss`}</inlineCode>{` will re-compile all of the Carbon Sass, create an `}<inlineCode parentName="p">{`app.scss`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory and in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`, import that new file.`}</p>
    <h5 {...{
      "id": "srcappjs"
    }}>{`src/App.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import './app.scss';
`}</code></pre>
    <p><em parentName="p">{`Note: To optimize our Sass compile time, we’ll be adding app-specific styling to`}</em>{` `}<inlineCode parentName="p">{`app.scss`}</inlineCode>{` `}<em parentName="p">{`and only modifying`}</em>{` `}<inlineCode parentName="p">{`index.scss`}</inlineCode>{` `}<em parentName="p">{`when necessary.`}</em></p>
    <p>{`Next we’ll import a `}<inlineCode parentName="p">{`Button`}</inlineCode>{` from Carbon to test that our dependencies are working properly. At the top of `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`, import the `}<inlineCode parentName="p">{`Button`}</inlineCode>{` by adding the following:`}</p>
    <h5 {...{
      "id": "srcappjs-1"
    }}>{`src/App.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Button } from 'carbon-components-react';
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`App`}</inlineCode>{` component return, you can now replace:`}</p>
    <h5 {...{
      "id": "srcappjs-2"
    }}>{`src/App.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
  Hello Carbon! Well, not quite yet. This is the starting point for the Carbon tutorial.
</div>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`with:`}</p>
    <h5 {...{
      "id": "srcappjs-3"
    }}>{`src/App.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<Button>Button</Button>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Congratulations, you’ve imported your first component! You should see a Carbon styled button on the page.`}</p>
    <h2 {...{
      "id": "add-ui-shell"
    }}>{`Add UI Shell`}</h2>
    <p><em parentName="p">{`Note: The UI Shell has experimental status at the moment. We do not recommend using it for production until it is stable, but if doing so, know that there may be breaking changes in the future.`}</em></p>
    <p>{`Next we’re going to create a React component called `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` to use with the UI Shell Carbon component. In the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory, create a `}<inlineCode parentName="p">{`components`}</inlineCode>{` directory and inside of that, a `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` directory. Create the following files inside `}<inlineCode parentName="p">{`src/components/TutorialHeader`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/components/TutorialHeader
├──_tutorial-header.scss
├──index.js
└──TutorialHeader.js
`}</code></pre>
    <h3 {...{
      "id": "add-ui-shell-sass"
    }}>{`Add UI Shell Sass`}</h3>
    <p>{`In `}<inlineCode parentName="p">{`index.scss`}</inlineCode>{` add the following feature-flag `}<strong parentName="p">{`above`}</strong>{` the Carbon styles import like so:`}</p>
    <h5 {...{
      "id": "srcindexscss-1"
    }}>{`src/index.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$feature-flags: (
  ui-shell: true,
);
`}</code></pre>
    <p>{`This is because our UI Shell is in experimental mode and the styles need to be manually imported.`}</p>
    <p>{`Next, in `}<inlineCode parentName="p">{`app.scss`}</inlineCode>{`, we’ll import our `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` styles. Your file should now look like this:`}</p>
    <h5 {...{
      "id": "srcappscss"
    }}>{`src/app.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import './components/TutorialHeader/tutorial-header.scss';
`}</code></pre>
    <h3 {...{
      "id": "import-and-export-the-header"
    }}>{`Import and export the header`}</h3>
    <p>{`In `}<inlineCode parentName="p">{`src/components/TutorialHeader/index.js`}</inlineCode>{`, import and export our `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` component like so:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheaderindexjs"
    }}>{`src/components/TutorialHeader/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import TutorialHeader from './TutorialHeader';
export default TutorialHeader;
`}</code></pre>
    <p>{`Next we’ll import our Carbon UI Shell components into `}<inlineCode parentName="p">{`TutorialHeader.js`}</inlineCode>{`. Set up the file like so:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheaderjs"
    }}>{`src/components/TutorialHeader/TutorialHeader.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
} from 'carbon-components-react/lib/components/UIShell';

const TutorialHeader = () => (
  <Header aria-label="Carbon Tutorial">
    <SkipToContent />
    <HeaderName href="/" prefix="IBM">
      Carbon Tutorial
    </HeaderName>
    <HeaderNavigation aria-label="Carbon Tutorial">
      <HeaderMenuItem href="/repos">Repositories</HeaderMenuItem>
    </HeaderNavigation>
    <HeaderGlobalBar />
  </Header>
);

export default TutorialHeader;
`}</code></pre>
    <p><em parentName="p">{`Note: you can find a description of the different components used UI Shell in our `}<a parentName="em" {...{
          "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/react/src/components/UIShell"
        }}>{`carbon-components-react`}</a>{` package.`}</em></p>
    <p><em parentName="p">{`Note: When creating navigation headers, it’s important to have a`}</em>{` `}<inlineCode parentName="p">{`Skip to content`}</inlineCode>{` `}<em parentName="p">{`link so keyboard users can skip the navigation items and go straight to the main content.`}</em></p>
    <p><em parentName="p">{`Note: It’s important that the`}</em>{` `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` `}<em parentName="p">{`returns the Carbon`}</em>{` `}<inlineCode parentName="p">{`Header`}</inlineCode>{` `}<em parentName="p">{`as it’s containing element, as we’ll later be rendering`}</em>{` `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` `}<em parentName="p">{`in`}</em>{` `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` `}<em parentName="p">{`as a preceeding sibling of`}</em>{` `}<inlineCode parentName="p">{`Content`}</inlineCode><em parentName="p">{`, another UI Shell component. Those components need to live one after another for the UI Shell to properly render.`}</em></p>
    <h3 {...{
      "id": "import-icons"
    }}>{`Import icons`}</h3>
    <p>{`Now let’s import the icons from our `}<inlineCode parentName="p">{`@carbon/icons-react`}</inlineCode>{` elements package. In the `}<inlineCode parentName="p">{`TutorialHeader.js`}</inlineCode>{` file, we need to import each individual icon we will use.`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheaderjs-1"
    }}>{`src/components/TutorialHeader/TutorialHeader.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Notification20 from '@carbon/icons-react/lib/notification/20';
import UserAvatar20 from '@carbon/icons-react/lib/user--avatar/20';
import AppSwitcher20 from '@carbon/icons-react/lib/app-switcher/20';
`}</code></pre>
    <p>{`Then we need to add the `}<inlineCode parentName="p">{`HeaderGlobalAction`}</inlineCode>{` component inside of the `}<inlineCode parentName="p">{`HeaderGlobalBar`}</inlineCode>{` where we will add our icons. These represent actions in the header a user can make. Replace:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheaderjs-2"
    }}>{`src/components/TutorialHeader/TutorialHeader.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<HeaderGlobalBar />
`}</code></pre>
    <p>{`With:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheaderjs-3"
    }}>{`src/components/TutorialHeader/TutorialHeader.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<HeaderGlobalBar>
  <HeaderGlobalAction aria-label="Notifications">
    <Notification20 />
  </HeaderGlobalAction>
  <HeaderGlobalAction aria-label="User Avatar">
    <UserAvatar20 />
  </HeaderGlobalAction>
  <HeaderGlobalAction aria-label="App Switcher">
    <AppSwitcher20 />
  </HeaderGlobalAction>
</HeaderGlobalBar>
`}</code></pre>
    <h3 {...{
      "id": "render-the-header"
    }}>{`Render the header`}</h3>
    <p>{`Next we’ll render our UI Shell by importing our `}<inlineCode parentName="p">{`TutorialHeader`}</inlineCode>{` component and `}<inlineCode parentName="p">{`Content`}</inlineCode>{` into `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`. Your imports should look like this:`}</p>
    <h5 {...{
      "id": "srcappjs-4"
    }}>{`src/App.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React, { Component } from 'react';
import './app.scss';
import { Button } from 'carbon-components-react';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import TutorialHeader from './components/TutorialHeader';
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`return`}</inlineCode>{` currently just contains a `}<inlineCode parentName="p">{`Button`}</inlineCode>{`. Let’s update that to include our imported components. This should look like the following:`}</p>
    <h5 {...{
      "id": "srcappjs-5"
    }}>{`src/App.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class App extends Component {
  render() {
    return (
      <>
        <TutorialHeader />
        <Content>
          <Button>Button</Button>
        </Content>
      </>
    );
  }
}
`}</code></pre>
    <p>{`You should now see a styled UI Shell header and a button below it.`}</p>
    <h2 {...{
      "id": "create-pages"
    }}>{`Create pages`}</h2>
    <p>{`Next thing we need to do is create the files for our content. Start by creating a folder called `}<inlineCode parentName="p">{`content`}</inlineCode>{` in `}<inlineCode parentName="p">{`src`}</inlineCode>{`. This should be a sibling of `}<inlineCode parentName="p">{`src/components`}</inlineCode>{`.`}</p>
    <p>{`Since our app will have two pages, we’ll create two folders in `}<inlineCode parentName="p">{`src/content`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/content
├── LandingPage
└── RepoPage
`}</code></pre>
    <p>{`Next we’ll set up these folders the same way we set up `}<inlineCode parentName="p">{`src/components/TutorialHeader`}</inlineCode>{`.`}</p>
    <p>{`Create the following files in the `}<inlineCode parentName="p">{`LandingPage`}</inlineCode>{` folder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/content/LandingPage
├── _landing-page.scss
├── index.js
└── LandingPage.js
`}</code></pre>
    <p>{`Create the following files in the `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` folder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`src/content/RepoPage
├── _repo-page.scss
├── index.js
└── RepoPage.js
`}</code></pre>
    <h3 {...{
      "id": "set-up-content-sass"
    }}>{`Set up content Sass`}</h3>
    <p>{`Next, we’ll import our content Sass files in `}<inlineCode parentName="p">{`app.scss`}</inlineCode>{`, like so:`}</p>
    <h5 {...{
      "id": "srcappscss-1"
    }}>{`src/app.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import './components/TutorialHeader/tutorial-header.scss';
@import './content/LandingPage/landing-page.scss';
@import './content/RepoPage/repo-page.scss';
`}</code></pre>
    <h3 {...{
      "id": "import-and-export-content-pages"
    }}>{`Import and export content pages`}</h3>
    <p>{`Now that our stylesheets are set up, we need to create our pages’ components. Starting with `}<inlineCode parentName="p">{`LandingPage`}</inlineCode>{`, just like with our header, we need to export the component in `}<inlineCode parentName="p">{`src/content/LandingPage/index.js`}</inlineCode>{` by adding:`}</p>
    <h5 {...{
      "id": "srccontentlandingpageindexjs"
    }}>{`src/content/LandingPage/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import LandingPage from './LandingPage';
export default LandingPage;
`}</code></pre>
    <p>{`Next in `}<inlineCode parentName="p">{`LandingPage.js`}</inlineCode>{` we’ll create our component.`}</p>
    <h5 {...{
      "id": "srccontentlandingpagelandingpagejs"
    }}>{`src/content/LandingPage/LandingPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';

const LandingPage = () => {
  return <div>LANDING PAGE</div>;
};

export default LandingPage;
`}</code></pre>
    <p>{`We’ll repeat this process with `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{`.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`src/content/RepoPage/index.js`}</inlineCode>{`, import and export the `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` component like so:`}</p>
    <h5 {...{
      "id": "srccontentrepopageindexjs"
    }}>{`src/content/RepoPage/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import RepoPage from './RepoPage';
export default RepoPage;
`}</code></pre>
    <p>{`Then in `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{` create the component.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';

const RepoPage = () => {
  return <div>REPO PAGE</div>;
};

export default RepoPage;
`}</code></pre>
    <p>{`Awesome! We’ve just created our content pages. Next thing we need to do is render them with our router.`}</p>
    <h2 {...{
      "id": "add-routing"
    }}>{`Add routing`}</h2>
    <p>{`We’ve updated our app to render our header, but now we need to add routing functionality. To do this we need to install `}<inlineCode parentName="p">{`react-router-dom`}</inlineCode>{`. Go ahead and stop your development server (with `}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{`) and then:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add react-router-dom
$ yarn start
`}</code></pre>
    <p>{`First, we need to wrap our app in the `}<inlineCode parentName="p">{`Router`}</inlineCode>{` component. In the root `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`, add the import:`}</p>
    <h5 {...{
      "id": "srcindexjs"
    }}>{`src/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { HashRouter as Router } from 'react-router-dom';
`}</code></pre>
    <p><em parentName="p">{`Note: We’re using`}</em>{` `}<inlineCode parentName="p">{`HashRouter`}</inlineCode>{` `}<em parentName="p">{`instead of`}</em>{` `}<inlineCode parentName="p">{`BrowserRouter`}</inlineCode>{` `}<em parentName="p">{`to simplify deployments in upcoming tutorial steps. Learn more about the React Router `}<a parentName="em" {...{
          "href": "https://reacttraining.com/react-router/web/api/BrowserRouter"
        }}>{`here`}</a>{`.`}</em></p>
    <p>{`Then, update the `}<inlineCode parentName="p">{`render()`}</inlineCode>{` function to include the `}<inlineCode parentName="p">{`Router`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srcindexjs-1"
    }}>{`src/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
`}</code></pre>
    <p>{`In order to render our content pages, we need to add the following imports in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` below our existing imports.`}</p>
    <h5 {...{
      "id": "srcappjs-6"
    }}>{`src/App.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Route, Switch } from 'react-router-dom';
import LandingPage from './content/LandingPage';
import RepoPage from './content/RepoPage';
`}</code></pre>
    <p>{`This allows us to use our page content components and routing components from `}<inlineCode parentName="p">{`react-router-dom`}</inlineCode>{`.`}</p>
    <p>{`Next thing we need to is update what we’re returning in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` . We currently just have a button in our content. In order to render our pages correctly, we need to delete the `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component within `}<inlineCode parentName="p">{`Content`}</inlineCode>{` (and remove the Button import).`}</p>
    <p>{`Now inside `}<inlineCode parentName="p">{`Content`}</inlineCode>{` we’ll add the following:`}</p>
    <h5 {...{
      "id": "srcappjs-7"
    }}>{`src/App.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<Switch>
  <Route exact path="/" component={LandingPage} />
  <Route path="/repos" component={RepoPage} />
</Switch>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`After that we need to do a couple quick fixes to the UI Shell to have it work with the React router.`}</p>
    <p>{`Add the `}<inlineCode parentName="p">{`Link`}</inlineCode>{` import in `}<inlineCode parentName="p">{`TutorialHeader.js`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheaderjs-4"
    }}>{`src/components/TutorialHeader/TutorialHeader.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Link } from 'react-router-dom';
`}</code></pre>
    <p>{`We need to use the `}<inlineCode parentName="p">{`Link`}</inlineCode>{` component instead of the default anchor elements to prevent full page reload when navigating to different pages with React Router. To use `}<inlineCode parentName="p">{`Link`}</inlineCode>{`, update the `}<inlineCode parentName="p">{`HeaderName`}</inlineCode>{` component to use the `}<inlineCode parentName="p">{`element`}</inlineCode>{` prop and replace the `}<inlineCode parentName="p">{`href`}</inlineCode>{` with `}<inlineCode parentName="p">{`to`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheaderjs-5"
    }}>{`src/components/TutorialHeader/TutorialHeader.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<HeaderName element={Link} to="/" prefix="IBM">
  Carbon Tutorial
</HeaderName>
`}</code></pre>
    <p>{`Do the same with the component that contains `}<inlineCode parentName="p">{`href="/repos"`}</inlineCode>{`, updating to:`}</p>
    <h5 {...{
      "id": "srccomponentstutorialheadertutorialheaderjs-6"
    }}>{`src/components/TutorialHeader/TutorialHeader.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<HeaderMenuItem element={Link} to="/repos">
  Repositories
</HeaderMenuItem>
`}</code></pre>
    <p>{`You should now have a working header that routes to different pages without full page reload!`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`We’re going to submit a pull request to verify completion of this tutorial step and demonstrate a couple related concepts.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`We have a `}<inlineCode parentName="p">{`ci-check`}</inlineCode>{` script defined in `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` that verifies file formatting for files that have been touched since the last Git commit with a tool called `}<a parentName="p" {...{
        "href": "https://prettier.io"
      }}>{`Prettier`}</a>{`. You’d typically also have that script run your test suite as part of your CI build. Go ahead and make sure everything looks good with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: If the`}</em>{` `}<inlineCode parentName="p">{`ci-check`}</inlineCode>{` `}<em parentName="p">{`is giving an error, it’s likely that some of your source files are not properly formatted. This could happen if your text editor isn’t formatting with Prettier on save. To get`}</em>{` `}<inlineCode parentName="p">{`ci-check`}</inlineCode>{` `}<em parentName="p">{`to pass, run`}</em>{` `}<inlineCode parentName="p">{`yarn format`}</inlineCode><em parentName="p">{` then re-run`}</em>{` `}<inlineCode parentName="p">{`yarn ci-check`}</inlineCode><em parentName="p">{`.`}</em></p>
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 1"
`}</code></pre>
    <p><em parentName="p">{`Note: You’ll notice that your commit includes binaries in the `}</em><inlineCode parentName="p">{`.yarn-offline-mirror`}</inlineCode><em parentName="p">{` folder. That’s expected as the repository is configured to run `}<a parentName="em" {...{
          "href": "https://yarnpkg.com/blog/2016/11/24/offline-mirror"
        }}>{`Yarn offline`}</a>{` for more reliable builds. Future tutorial steps that don’t install new packages won’t have `}</em><inlineCode parentName="p">{`.yarn-offline-mirror`}</inlineCode><em parentName="p">{` commit changes.`}</em></p>
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin react-step-1
`}</code></pre>
    <p><em parentName="p">{`Note: If your Git remote protocol is HTTPS instead of SSH, you may be prompted to authenticate with GitHub when you push changes. If your GitHub account has two-factor authentication enabled, we recommend that you follow these instructions to `}<a parentName="em" {...{
          "href": "https://help.github.com/en/articles/creating-a-personal-access-token-for-the-command-line"
        }}>{`create a personal access token for the command line`}</a>{`. That lets you use your token instead of password when performing Git operations over HTTPS.`}</em></p>
    <p><em parentName="p">{`Note: If you receive a`}</em>{` `}<inlineCode parentName="p">{`non-fast-forward`}</inlineCode>{` `}<em parentName="p">{`error, it’s likely that your forked repository is behind the original repository and needs updated. This can happen if the tutorial was updated after you began working on it. To fix, run`}</em>{` `}<inlineCode parentName="p">{`git pull upstream react-step-1`}</inlineCode>{` `}<em parentName="p">{`to merge the changes into your branch, then you can try pushing again. Or, you can `}<a parentName="em" {...{
          "href": "https://help.github.com/en/articles/syncing-a-fork"
        }}>{`manually merge`}</a>{` in the upstream changes.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` to “Compare & pull request”. In doing so, make sure that you are comparing to `}<inlineCode parentName="p">{`react-step-1`}</inlineCode>{` into `}<inlineCode parentName="p">{`base: react-step-1`}</inlineCode>{`. Take notice of the `}<a parentName="p" {...{
        "href": "https://www.netlify.com"
      }}>{`Netlify`}</a>{` bot that deploys a preview of your PR every time that you push new commits. These previews can be shared and viewed by anybody to assist the PR review process.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      